import { createSelector, Selector } from "@reduxjs/toolkit";

import { RootState } from "../../types";
import { DocumentsState } from "./types";

const selectSelf: Selector<RootState, DocumentsState> = (state) => state.documents;

export const selectOrganizationDocuments = createSelector(selectSelf, (state) => {
  return state.organizationDocuments;
});

export const selectCategories = createSelector(selectSelf, (state) => {
  return state.categories;
});

export const selectDocuments = createSelector(selectSelf, (state) => {
  return state.documents;
});

export const selectBorrowerDocuments = createSelector(selectSelf, (state) => {
  return state.borrowerDocuments;
});

export const selectCheckedDocuments = createSelector(selectSelf, (state) => {
  return state.selectedDocumentsIds
})

export const selectDocumentSort = createSelector(selectSelf, (state) => {
  return state.sort;
})