import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container } from "../../components/Container";
import { Button } from "../../ui/common/Button";
import { DocumentsFilters } from "./components/DocumentsFilters";
import { DocumentsListTable } from "./components/DocumentsListTable";
import { DocumentsListTableBody } from "./components/DocumentsListTableBody";
import { DocumentsListTableHeader } from "./components/DocumentsListTableHeader";
import {
  useDeleteDocumentsMutation,
  useGetDocumentsQuery,
  useLazyGetDocumentsQuery,
} from "../../../services/rtkQuery/documents";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectCheckedDocuments, selectDocuments, selectDocumentSort } from "../../../store/modules/documents/selectors";
import Pagination from "../../ui/common/Pagination";
import { Option, SelectBox } from "../../ui/common/SelectBox";
import { numberPageUser } from "../../../utill/contants";
import { CreateDocumentModal } from "./components/CreateDocumentModal";
import { useCreateDocument } from "../../../hooks/useCreateDocument";
import TrashIcon from "../../assets/icons/TrashIcon";
import { clearSelectedDocuments } from "../../../store/modules/documents";

const Documents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numberPageUser[1]);
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "",
  });
  const [selectedBorrowers, setSelectedBorrowers] = useState({
    value: "",
    label: "",
  });
  const [selectedOrganization, setSelectedOrganization] = useState({
    value: "",
    label: "",
  });
  const [status, setStatus] = useState<Option | undefined>(undefined);
  const [name, setName] = useState("");
  const [isOpenOrganizationModal, setOpenOrganizationModal] = useState(false);

  const [file, setFile] = useState();

  const dispatch = useAppDispatch();
  
  const sort = useAppSelector(selectDocumentSort);

  const {
    control,
    handleSubmit,
    organizationOptions,
    categoriesOptions,
    errorUploading,
    errors,
    isFileLoading,
  } = useCreateDocument(file, closeOrganizationModal);

  useGetDocumentsQuery({});
  const documents = useAppSelector(selectDocuments);
  const selectedDocuments = useAppSelector(selectCheckedDocuments);

  const [getDocuments] = useLazyGetDocumentsQuery();
  const [deleteDocuments, { isSuccess: isDeleteDocsSuccess }] = useDeleteDocumentsMutation();

  const openOrganizationModal = () => {
    setOpenOrganizationModal(true);
  };

  function closeOrganizationModal() {
    setOpenOrganizationModal(false);
  }
  const onChangeStatus = (event: Option | Option[]) => {
    setStatus(event as Option);
  };

  useEffect(() => {
    getDocuments({
      page: currentPage,
      limit: +itemsPerPage.value,
      title: name,
      categoryId:
        selectedCategory.value.length > 0 ? +selectedCategory.value : undefined,
      organizationId:
        selectedOrganization.value.length > 0
          ? +selectedOrganization.value
          : undefined,
      status: status && status.value.length > 0 ? status.value : undefined,
      sort: sort ?? 'title:asc',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    itemsPerPage,
    name,
    selectedCategory,
    selectedOrganization,
    status,
    selectedBorrowers,
    sort
  ]);

  useEffect(() => {
    if (isDeleteDocsSuccess) {
      toast.success("Documents were deleted");
    }
  }, [isDeleteDocsSuccess]);

  const onChangeSelectedValue = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setSelectedCategory(e);
  };
  const onChangeSelectedBorrower = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setSelectedBorrowers(e);
  };

  const onChangeSelectedOrganization = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setSelectedOrganization(e);
  };

  const startEntry = documents && documents?.count > 0 ? (currentPage - 1) * +itemsPerPage.value + 1 : 0;
  const endEntry = Math.min(currentPage * +itemsPerPage.value, documents?.count || 0);

  const handleDeleteSelectedDocuments = async () => {
    await deleteDocuments({ documentIds: selectedDocuments });
    dispatch(clearSelectedDocuments());
  }

  return (
    <div>
      <Container fullHeight={false}>
        <div>
          <div className="flex justify-between items-center w-full mb-[24px]">
            <div className="w-[90%]">
              <p className="text-[24px] text-[#474747] font-bold">Documents</p>
              <p className="text-[14px] text-[#717984] folt-medium">
                Manage documents
              </p>
            </div>
            <div className="w-[15%] text-[15.5px]">
              <Button
                text="+ Upload New Document"
                onClick={openOrganizationModal}
              />
            </div>
          </div>
          <div className="mb-[24px]">
            <DocumentsFilters
              name={name}
              setName={setName}
              selectedCategory={selectedCategory}
              selectedOrganization={selectedOrganization}
              onChangeSelectedValue={onChangeSelectedValue}
              onChangeSelectedOrganization={onChangeSelectedOrganization}
              status={status}
              onChangeStatus={onChangeStatus}
            />
          </div>
          <div>
            <div>
              <DocumentsListTable
                BodyComponent={DocumentsListTableBody}
                HeaderComponent={DocumentsListTableHeader}
                documentsList={documents?.rows}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[70%] justify-start">

              <Pagination
                itemsPerPage={+itemsPerPage.value}
                userCount={documents?.count}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>

            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <div className="flex items-center gap-4">
                <button
                  onClick={handleDeleteSelectedDocuments}
                  className={`flex items-center gap-2 px-4 py-2 rounded ${selectedDocuments?.length === 0
                      ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                      : "bg-[#E04D4D] text-white hover:bg-[#C0392B] cursor-pointer"
                    }`}
                  disabled={selectedDocuments?.length === 0}
                >
                  <TrashIcon />
                </button>
                <p className="text-[13px] text-[#717984]">
                  Showing {startEntry} to {endEntry} of {documents?.count} entries
                </p>
              </div>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) =>
                    setItemsPerPage(value as Option)
                  }
                  options={numberPageUser}
                  placeholder="Show 8"
                  multiple={false}
                  searchEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isOpenOrganizationModal && (
        <CreateDocumentModal
          isOpenModal={isOpenOrganizationModal}
          closeModal={closeOrganizationModal}
          control={control}
          handleSubmit={handleSubmit}
          organizationOptions={organizationOptions}
          categoriesOptions={categoriesOptions}
          file={file}
          setFile={setFile}
          action={"create"}
          errorUploading={errorUploading}
          errors={errors}
          isFileLoading={isFileLoading}
        />
      )}
    </div>
  );
};

export default Documents;
