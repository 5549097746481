import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  useCreateBorrowerDocumentMutation,
  useGetCategoriesQuery,
  useUploadFileMutation,
} from "../services/rtkQuery/documents";

import { selectCategories } from "../store/modules/documents/selectors";
import { getOptions } from "../utill/helpers";
import { getGeneralSelectBoxSchema } from "../utill/validation/validationSchemas";

const documentSchema = Yup.object().shape({
  title: Yup.string().required("Document name is required"),
  categoryId: getGeneralSelectBoxSchema("Category is required"),
  status: Yup.string(),
});

export const useCreateBorrowerDocument = (file: any, onSuccess: () => void) => {
  const [uploadFileError, setUploadFileError] = useState("");
  const [isFileAdded, setIsFileAdded] = useState(false);

  useGetCategoriesQuery();

  const [uploadFile, { isLoading: isFileLoading }] = useUploadFileMutation();
  const [createBorrowerDocument, { isSuccess: isFileAddedSuccess }] =
    useCreateBorrowerDocumentMutation();

  const categories = useSelector(selectCategories);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoryId: { value: "", label: "" },
      title: "",
      status: "active",
    },
    resolver: yupResolver(documentSchema),
  });

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  useEffect(() => {
    if (file) {
      setIsFileAdded(true);
    } else {
      setIsFileAdded(false);
    }
  }, [file]);

  useEffect(() => {
    if (isFileAddedSuccess) {
      toast.success("Document was added");
    }
  }, [isFileAddedSuccess]);

  const onSubmit = (data: any) => {
    if (isFileAdded) {
      const formData = new FormData();
      formData.append("file", file);
      uploadFile(formData).then((res: any) => {
        if (res.error) {
          setUploadFileError(res.error.error);
          return;
        }
        data.url = res.data.data;
        data.status = "active";
        data.categoryId = data.categoryId.value;

        createBorrowerDocument({...data, rawFileName: file.name }).then(() => onSuccess());
      });
    } else {
      toast.warn("Please add your document");
    }
  };
  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    categoriesOptions,
    isFileLoading,
    errors,
  };
};
