import * as Yup from "yup";

export const getUserSchema = () => {
  return Yup.object().shape({
    email: getEmailSchema(),
    fullName: Yup.string().required("Please enter full name"),
    password: Yup.string()
      .required("Please enter a valid password")
      .min(6, "Please enter at least 6 characters"),
    role: getUserRolesSchema(),
  });
};

export const getEmailSchema = () => {
  return Yup.string()
    .required("Please enter a valid email")
    .email("Invalid email format")
    .matches(/^[^.,][^@]*[^.,]@[^.,]+\.[^.,]+$/, "Invalid email format");
};

export const getUserRolesSchema = () => {
  return Yup.object().shape({
    value: Yup.string().required("Role is required"),
    label: Yup.string().required("Role is required"),
  });
};

export const getOptionalSelectBoxSchema = () => {
  // return Yup.object().shape({
  //   value: Yup.string(),
  //   label: Yup.string(),
  // });
  return Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    })
  );
};

export const getGeneralSelectBoxSchema = (message: string) => {
  return Yup.object().shape({
    value: Yup.string().required(message),
    label: Yup.string().required(message),
  });
};
