import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { documentsApi } from "../../../services/rtkQuery/documents";
import { DocumentsState, GetCategoriesResponse, GetDocumentsResponse } from "./types";

export const initialState: DocumentsState = {
  organizationDocuments: null,
  categories: null,
  documents: null,
  borrowerDocuments: null,
  selectedDocumentsIds: [],
  sort: 'createdAt:desc'
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    toggleSelectDocument: (state, action: PayloadAction<number>) => {
      const index = state.selectedDocumentsIds.indexOf(action.payload);
      if (index > -1) {
        state.selectedDocumentsIds.splice(index, 1);
      } else {
        state.selectedDocumentsIds.push(action.payload);
      }
    },
    setSelectDocument: (state, action: PayloadAction<number[]>) => {
      state.selectedDocumentsIds = action.payload;
    },
    clearSelectedDocuments: (state) => {
      state.selectedDocumentsIds = [];
    },
    setSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        documentsApi.endpoints.getOrganizationsDocuments.matchFulfilled,
        (state, { payload }: PayloadAction<GetDocumentsResponse>) => {
          state.organizationDocuments = payload && payload.data.documents;
        }
      )
      .addMatcher(
        documentsApi.endpoints.getCategories.matchFulfilled,
        (state, { payload }: PayloadAction<GetCategoriesResponse>) => {
          state.categories = payload && payload.data.categories;
        }
      )
      .addMatcher(
        documentsApi.endpoints.getDocuments.matchFulfilled,
        (state, { payload }: PayloadAction<GetDocumentsResponse>) => {
          state.documents = payload && payload.data.documents;
        }
      )
      .addMatcher(
        documentsApi.endpoints.getBorrowerDocuments.matchFulfilled,
        (state, { payload }: PayloadAction<GetDocumentsResponse>) => {
          state.borrowerDocuments = payload && payload.data.documents;
        }
      );
  },
});

export const documentsReducer = documentsSlice.reducer;
export const { toggleSelectDocument, setSelectDocument, clearSelectedDocuments, setSort } = documentsSlice.actions;
