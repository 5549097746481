import React, { FC } from "react";
import { Document } from "../../../../store/modules/documents/types";

interface BorrowerDocumentsListTableProps {
  HeaderComponent: React.ElementType;
  BodyComponent: React.ElementType;
  documentsList?: Document[];
}

export const BorrowerDocumentsListTable: FC<BorrowerDocumentsListTableProps> = ({
  HeaderComponent,
  BodyComponent,
  documentsList,
}) => {
  return (
    <table className="flex w-full flex-wrap">
      <HeaderComponent documentsList={documentsList} />
      <BodyComponent documentsList={documentsList} />
    </table>
  );
};
