import SortIcon from "../../../assets/icons/SortIcon";
import { Document, DocumentSortFields } from "../../../../store/modules/documents/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setSelectDocument, setSort } from "../../../../store/modules/documents";
import { selectCheckedDocuments, selectDocumentSort } from "../../../../store/modules/documents/selectors";

interface DocumentsListTableHeaderProps {
  documentsList: Document[];
}

export const DocumentsListTableHeader: React.FC<DocumentsListTableHeaderProps> = ({ documentsList }) => {
  const dispatch = useAppDispatch();
  const selectedDocuments = useAppSelector(selectCheckedDocuments);
  const sort = useAppSelector(selectDocumentSort)

  const isAllSelected = documentsList?.length > 0 && selectedDocuments?.length === documentsList?.length;

  const handleSelectAll = () => {
    if (isAllSelected) {
      dispatch(setSelectDocument([]));
    } else {
      const ids = documentsList.map((doc) => doc.id);
      dispatch(setSelectDocument(ids));
    }
  };

  const handleSort = (field: DocumentSortFields) => {
    const [sortField, sortOrder] = sort.split(':');
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    const newSort = `${field}:${newSortOrder}`;

    dispatch(setSort(newSort));
  };


  return (
    <thead className="flex w-full justify-between gap-[10px]">
      <th className="flex items-center gap-1 w-[1%] py-3">
        <input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
      </th>
      <th className="flex items-center gap-1 w-[12%] py-3" onClick={() => handleSort(DocumentSortFields.TITLE)}>
        <p className="text-center text-[15px]">Document Title</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">Category</p>
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">File Name</p>
      </th>
      <th className="flex justify-start items-center gap-1 w-[18%] py-3" onClick={() => handleSort(DocumentSortFields.ORGANIZATION_NAME)}>
        <p className="text-center text-[15px]">Charter School Partner</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[14%] py-3" onClick={() => handleSort(DocumentSortFields.ORGANIZATION_NAME)}>
        <p className="text-center text-[15px]">Primary School Contact</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">Carewolf</p>
        {/* <SortIcon /> */}
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3" onClick={() => handleSort(DocumentSortFields.DATE_UPLOAD)}>
        <p className="text-center text-[15px]">Date Upload</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[8%] py-3">
        <p className="text-right text-[15px]">Status</p>
        {/* <SortIcon /> */}
      </th>
      <th className="flex justify-end items-center w-[12%] py-3">
        <p className="text-right text-[15px]">Action</p>
      </th>
    </thead>
  );
};
