import { Organization } from "../employee/types";

export enum DocumentSortFields {
  TITLE = 'title',
  ORGANIZATION_NAME = 'organizationName',
  DATE_UPLOAD = 'createdAt'
}

export interface DocumentsState {
  organizationDocuments: DocumentType | null;
  categories: Category[] | null;
  documents: DocumentType | null;
  borrowerDocuments: DocumentType | null;
  selectedDocumentsIds: number[];
  sort: string;
}

interface GetDocumentsData {
  documents: DocumentType;
}

interface DocumentType {
  count: number;
  rows: Document[];
}

export interface Document {
  [x: string]: any;
  Category: {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  Organization: Organization;
  categoryId: number;
  createdAt: string;
  deletedAt: null | string;
  deletedUserId: null | string;
  id: number;
  lastViewedAt: null | string;
  organizationId: number;
  status: string;
  title: string;
  type: string;
  updatedAt: string;
  url: null | string;
  userId: number;
}
export interface GetDocumentsResponse {
  data: GetDocumentsData;
  success: boolean;
}

interface Category {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

export interface GetCategoriesResponse {
  data: GetCategoriesData;
  success: boolean;
}

interface GetCategoriesData {
  categories: Category[];
}
