import React, { FC, useState } from "react";
import { Modal } from "../../../components/Modal";
import CropIcon from "../../../assets/icons/CropIcon";
import { Button } from "../../../ui/common/Button";
import { TextField } from "../../../ui/common/TextField";
import SchoolIcon from "../../../assets/icons/SchoolIcon";
import { Control, Controller } from "react-hook-form";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { FileInput } from "../../../components/FileInput";
import { useAppSelector } from "../../../../store/hooks";
import { selectUser } from "../../../../store/modules/user/selectors";

interface CreateDocumentModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
  control: Control | any;
  organizationOptions: Option[];
  categoriesOptions: Option[];
  file: File | any;
  setFile: (file: any) => void;
  action: string;
  errorUploading?: string;
  errors?: any;
  isFileLoading?: boolean;
}

export const CreateDocumentModal: FC<CreateDocumentModalProps> = ({
  isOpenModal,
  closeModal,
  handleSubmit,
  control,
  organizationOptions,
  categoriesOptions,
  file,
  setFile,
  action,
  errorUploading,
  errors,
  isFileLoading,
}) => {
  const isCreate = action === "create";
  const user = useAppSelector(selectUser);

  const isAssignedBorrowerAdmin = user?.type === "borrower_admin" && user?.organizationId;

  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[70%]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <form className="flex justify-between" onSubmit={handleSubmit}>
          <div className="w-[48%]">
            <p className="text-[32px] font-medium text-[#474747]">
              {isCreate ? "Upload New Document" : "Update Document"}
            </p>
            <p className="text-[16px] text-[#717984] mb-[24px]">Enter document information</p>
            <div>
              <p className="text-[20px] font-medium text-[#474747] mb-[16px]">
                Document Information
              </p>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      label="Document Name*"
                      placeholder="Enter document name"
                      type="input"
                    />
                    {errors?.title && (
                      <p className="text-[red] text-[13px]">{errors?.title?.message}</p>
                    )}
                  </>
                )}
              />
            </div>
            {!isAssignedBorrowerAdmin && (
              <div>
                <Controller
                  control={control}
                  name="organizationIds"
                  render={({ field }) => (
                    <>
                      <SelectBox
                        disabled={!isCreate}
                        value={field.value}
                        icon={<SchoolIcon />}
                        onChange={field.onChange}
                        options={organizationOptions}
                        label="Assign to School Partner"
                        placeholder="Select School Partner"
                        multiple={true}
                      />
                      {errors?.organizationId?.value && (
                        <p className="text-[red] text-[13px]">
                          {errors?.organizationId.value.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            )}

            <div className="mt-[16px]">
              <Controller
                control={control}
                name="categoryId"
                render={({ field }) => (
                  <>
                    <SelectBox
                      value={field.value}
                      disabled={!isCreate}
                      onChange={field.onChange}
                      options={categoriesOptions}
                      label="Category*"
                      placeholder="Select category"
                      multiple={false}
                    />
                    {errors?.categoryId?.value && (
                      <p className="text-[red] text-[13px]">{errors?.categoryId.value.message}</p>
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="w-[48%]">
            <div className="flex flex-col h-[90%]">
              <p className="text-[20px] font-medium text-[#474747] mb-[30px]">
                Upload New Document*
              </p>
              <FileInput
                file={file}
                changeFile={setFile}
                disabled={!isCreate}
                isFileLoading={isFileLoading}
              />
              {errorUploading && <p className="text-[red]">{errorUploading}</p>}
            </div>
            <div className="flex justify-end h-[10%]">
              <div className="w-[50%]">
                <Button text="Save" type="submit" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
