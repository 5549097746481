import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setSelectDocument, setSort } from "../../../../store/modules/documents";
import SortIcon from "../../../assets/icons/SortIcon";
import { Document, DocumentSortFields } from "../../../../store/modules/documents/types";
import { selectCheckedDocuments, selectDocumentSort } from "../../../../store/modules/documents/selectors";
import { useEffect } from "react";

interface BorrowerDocumentsListTableHeaderProps {
  documentsList: Document[];
}

export const BorrowerDocumentsListTableHeader: React.FC<BorrowerDocumentsListTableHeaderProps> = ({
  documentsList
}) => {
  const dispatch = useAppDispatch();
  const selectedDocuments = useAppSelector(selectCheckedDocuments);
  const sort = useAppSelector(selectDocumentSort);

  useEffect(() => {
    console.log("CURR SORT: ", sort);
  }, [sort])

  const isAllSelected = documentsList?.length > 0 && selectedDocuments?.length === documentsList?.length;

  const handleSelectAll = () => {
    if (isAllSelected) {
      dispatch(setSelectDocument([]));
    } else {
      const ids = documentsList.map((doc) => doc.id);
      dispatch(setSelectDocument(ids));
    }
  };

  const handleSort = (field: DocumentSortFields) => {
    const [sortField, sortOrder] = sort.split(':');
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    const newSort = `${field}:${newSortOrder}`;

    dispatch(setSort(newSort));
  };

  return (
    <thead className="w-full">
      <tr className="flex w-full justify-between gap-[10px]">
        <th className="flex items-center gap-1 w-[1%] py-3">
          <input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
        </th>
        <th className="flex items-center gap-1 w-[25%] py-3" onClick={() => handleSort(DocumentSortFields.TITLE)}>
          <p className="text-center text-[15px]">Document Title</p>
          <SortIcon />
        </th>
        <th className="flex justify-start items-center gap-1 w-[25%] py-3">
          <p className="text-center text-[15px]">Category</p>
        </th>
        <th className="flex justify-start items-center gap-1 w-[25%] py-3">
          <p className="text-center text-[15px]">File Name</p>
        </th>
        <th className="flex justify-start items-center gap-1 w-[25%] py-3" onClick={() => handleSort(DocumentSortFields.DATE_UPLOAD)}>
          <p className="text-center text-[15px]">Date Upload</p>
          <SortIcon />
        </th>
        <th className="flex justify-end items-center w-[25%] py-3">
          <p className="text-right text-[15px]">Action</p>
        </th>
      </tr>
    </thead>
  );
};
